import { Navigate, Outlet } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { NOT_FOUND } from "../constans/Route";
import Sidebar from "../layout/sidebar/Sidebar";
const RequireAuth = ({
  path,
  allowedRoles,
  visible,
  show,
  toggleSidebar,
  isSidebarOpen,
  setSidebarOpen,
  loggedIn,
}) => {
  const role = secureLocalStorage.getItem("role");
  const permission = [role];

  const showPage = show === undefined ? true : show;
  


  if (!role) {
    return <Navigate to="/" replace />;
  }



  return loggedIn ? (
    permission?.find((role) => allowedRoles?.includes(role)) && showPage ? (
      <div
        className={
          role === "admin" ? " main__section " : "main__section__company"
        }
      >
        <Sidebar
          visible={visible}
          show={show}
          toggleSidebar={toggleSidebar}
          isSidebarOpen={isSidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        <div className="p-4 lg:ml-64 w-[100vw] lg:w-[calc(100vw_-_260px)]">
          <div className="p-2 mt-[75px] 2xl:mt-[100px] ">
            <Outlet />
          </div>
        </div>
      </div>
    ) : (
      <Navigate to={NOT_FOUND} replace />
    )
  ) : (
    <Navigate to="/" />
  );

  // return role ? Component : <Outlet />;
};
export default RequireAuth;
