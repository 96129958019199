import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import "./Modal.css";
import { AiOutlineClose } from "react-icons/ai";


const NewModal = props => {
    const closeOnEscapeKeyDown = e => {
        if ((e.charCode || e.keyCode) === 27) {
            props.onClose();
        }
    };

    useEffect(() => {
        document.body.addEventListener("keydown", closeOnEscapeKeyDown);
        return function cleanup() {
            document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
        };
    }, []);

    return ReactDOM.createPortal(
        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
            <div className={`modal ${props.className}`} onClick={props.onClose}>
                <div className={`modal-content-new ${props.width ? props.width : 'w-[500px]'} ${props.height ? props.height : 'h-[auto]'} border-0 rounded-2xl`} onClick={e => e.stopPropagation()}>
                    <div className="modal-header">
                        <h4 className={`modal-title ${props.headText ? props.headText : 'text-[26px]'}  font-semibold ps-3`}>{props.title}</h4>
                        <div className="bg-[#EFEFEF] p-2 rounded-3xl">
                            <AiOutlineClose
                                type="button"
                                size={18}
                                onClick={props.onClose}
                            />
                        </div>
                    </div>
                    <div className={props.addModalBodyClass ? "p-[0px]" : "modal-body"}>{props.children}</div>
                </div>
            </div>
        </CSSTransition>,
        document.getElementById("root")
    );
};

export default NewModal;
