import React, { useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import Select from "react-select";
import {
  GET_EMP_LIST_PROJECT,
  ADD_PROJECT_MEMBERS,
  GET_PROJECT_MEMBERS,
  REMOVE_PROJECT_MEMBER,
  UPDATE_PROJECT_MEMBER,
} from "../../../components/utils";
import { getRequest, patchRequest, postRequest } from "../../../components/axiosClient";
import { useNavigate } from "react-router-dom";
import makeAnimated from "react-select/animated";
import NewModal from "../Modal/NewModal";
import { errorNotification, successNotification } from "../../../components/toast-notification/common-toast";
import secureLocalStorage from "react-secure-storage";
import { Switch } from "@mui/material";

const Members = ({ projectId }) => {
  const [listShow, setListShow] = useState(false);

  const [removeModal, setRemoveModal] = useState(false);
  const [removeModalLoading, setRemoveModalLoading] = useState(false);
  const [removeMemberData, setRemoveMemberData] = useState({});

  const [members, setMembers] = useState([]);
  const [selectedNames, setSelectedNames] = useState([]);
  const [isActive, setIsActive] = useState(true);
  const [selectedMember, setSelectedMember] = useState(null);
  const navigate = useNavigate();
  const animatedComponents = makeAnimated();
  const role_accesses = JSON.parse(secureLocalStorage.getItem("role_accesses")).find((access) => access.module.name === "Projects")?.access_type !== "Read";
  const [names, setNames] = useState([]);
  const [projectMembers, setProjectMembers] = useState([]);


  const AddProjectMembers = async () => {
    try {
      const payload = {
        project_id: projectId,
        user_ids: selectedNames.map((name) => name.value),
        is_active: true,
      };
      const res = await postRequest(
        `${ADD_PROJECT_MEMBERS}`,
        payload,
        navigate
      );
      if (res.response.data.success) {
        await GetProjectMembersTableData();
        closeAddMembers();
      }
    } catch (error) {
      errorNotification(error);
    }
  };

  const GetProjectMembers = async () => {
    try {
      const res = await getRequest(
        `${GET_EMP_LIST_PROJECT}?project_id=${projectId}`,
        null,
        navigate
      );
      if (res.type === 2) {
        errorNotification(res.errormessage);
        navigate("/404");
      } else {
        const nameList = res.data.data.map((item) => {
          return {
            value: item.id,
            label: `${item.first_name} ${item.last_name} ( ${item?.technology?.name || item?.role?.name} )`,
          };
        });
        setNames(nameList);
      }
    } catch (error) {
      errorNotification(error);
    }
  };

  const GetProjectMembersTableData = async () => {
    try {
      const res = await getRequest(
        `${GET_PROJECT_MEMBERS}?project_id=${projectId}`,
        null,
        navigate
      );
      if (res.data.success) {
        setMembers(res.data.data);
      }
    } catch (error) {
      errorNotification(error);
    }
  };

  useEffect(() => {
    GetProjectMembersTableData();
  }, []);

  const closeAddMembers = () => {
    setSelectedNames([]);
    setIsActive(true);
    setSelectedMember(null);
    setListShow(false);
  }
  const handleAdd = async () => {
    setSelectedNames([]);
    setIsActive(true);
    setSelectedMember(null);
    setListShow(true);
    await GetProjectMembers();
  };
  const handleRemoveModelOpen = (memberData) => {
    setRemoveMemberData(memberData);
    setRemoveModal(true);
  }
  const handleRemove = async () => {
    setRemoveModalLoading(true);
    const payload = {
      project_id: projectId,
      user_id: removeMemberData?.user?.id,
    };

    try {
      const res = await patchRequest(`${REMOVE_PROJECT_MEMBER}`, payload, navigate);
      if (res.type === 1) {
        setRemoveMemberData({})
        await GetProjectMembersTableData();
        successNotification(res.response.data.message);
      }
      if (res.type === 2) {
        errorNotification(res.errormessage || res.errors);
      }
    } catch (error) {
      console.error("Error removing member: ", error);
    }
    setRemoveModalLoading(false);
    setRemoveModal(false);
  };

  const handleSwitchChange = async (id, allowed) => {
    setRemoveModalLoading(true);
    const payload = {
      project_id: projectId,
      user_id: id,
      manual_timer_allow: !allowed,
    };
    try {
      const res = await patchRequest(`${UPDATE_PROJECT_MEMBER}`, payload, navigate);
      if (res.type === 1) {
        await GetProjectMembersTableData();
        // successNotification(res.response.data.message);
      }
      if (res.type === 2) {
        errorNotification(res.errormessage || res.errors);
      }
    } catch (error) {
      console.error("Error removing member: ", error);
    }
    setRemoveModalLoading(false);
  };

  return (
    <div className="hunter_audit_dashboard">
      <div className="client__subsection">
        {role_accesses &&
          <button className="add__client mx-3 " onClick={handleAdd}>
            + Add Member
          </button>
        }
        <div className="client__listing">
          <div className="client__data pb-4">
            <table className="table client__list__table">
              <thead className="report__header__border">
                <tr className="border-style">
                  <th scope="col" className="font__weight header__first__child">
                    Name
                  </th>
                  <th scope="col" className="font__weight header__first__child">
                    Role
                  </th>
                  <th scope="col" className="font__weight">
                    Allow Manual Hours
                  </th>
                  {
                    role_accesses &&
                    <th scope="col" className="font__weight text-right pr-2">
                      Actions
                    </th>
                  }
                </tr>
              </thead>
              <tbody>
                {members.map((member) => (
                  <tr key={member.id}>
                    <td className="table_tr_style align-middle">
                      {`${member.user.first_name} ${member.user.last_name}`}
                    </td>
                    <td className="table_tr_style align-middle">
                      {member?.user?.role?.name || "--"}
                    </td>
                    <td className="table_tr_style align-middle">
                      {(member?.user?.role?.name !== "CEO" && member?.user?.role?.name !== "CTO") && (
                        <Switch
                          checked={member?.manual_timer_allow}
                          onChange={() => handleSwitchChange(member.user.id, member.manual_timer_allow)}
                          disabled={!role_accesses}
                        />
                      )}
                    </td>
                    <td className="text-center align-middle d-flex justify-content-end !border-b-0">
                      {(member?.user?.role?.name !== "CEO" && member?.user?.role?.name !== "CTO") && role_accesses && (
                        <button
                          className="bg-[#f47e53] text-[#fff] w-[fit-content] interFontFamily py-[8px] px-[18px] rounded-[6px] text-[14px] cursor-pointer font-[700] ms-2"
                          onClick={() => handleRemoveModelOpen(member)}
                        >
                          Remove
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Modal
          className="client-modal"
          title={selectedMember ? "Edit Member" : "Add Member"}
          onClose={() => setListShow(false)}
          show={listShow}
        >
          <div className="modal-body add__client__form">
            <div className="mt-3">
              <h6 className="modal-heading">Name</h6>
              <Select
                className="basic-single"
                classNamePrefix="select"
                onChange={setSelectedNames}
                options={names}
                isMulti
                components={animatedComponents}
                value={selectedNames}
              />
            </div>

            <div className="add__close__section mt-3">
              <button
                type="button"
                className="client__save__btn w-100"
                onClick={AddProjectMembers}
                options={projectMembers}
              >
                {selectedMember ? "Save" : "Add"}
              </button>
            </div>
          </div>
        </Modal>
        <NewModal
          title="Are you Sure?"
          onClose={() => setRemoveModal(false)}
          show={removeModal}
        >
          <div className="modal-body add__cmp__form">
            <h6>Do you really want to remove this member?</h6>
            <div className="add__close__section">
              <button
                type="button"
                className="bg-[#F5F5F5] text-[#5D5D5D] w-[fit-content] px-4 py-2 rounded-[12px] me-3"
                onClick={() => setRemoveModal(false)}
              >
                No
              </button>
              <button
                type="button"
                className="bg-[#f47e53] text-[#fff] border-[1px] flex items-center  w-[fit-content] px-3 py-2 rounded-[12px] whitespace-nowrap"
                onClick={handleRemove}
                disabled={removeModalLoading}
              >
                {removeModalLoading && (
                  <span
                    className="spinner-border spinner-border-sm  mr-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}{" "}
                Yes{" "}
              </button>
            </div>
          </div>
        </NewModal>
      </div>
    </div>
  );
};

export default Members;
