import React from 'react'
import './NotFound.css'
import NotFoundPage from '../components/assets/404_page.svg'
import NotFoundPageText from '../components/assets/404_page_text.svg'

const NotFound = () => {
    return (
        <div className='not__found__main'>
            <div className="d-flex flex-col justify-center items-center w-full">
                <img src={NotFoundPage} alt="Not Found" className='not__found__img' loading='lazy' />
                <img src={NotFoundPageText} alt="Not Found Text" className='not__found__text' loading='lazy' />
            </div>
        </div>
    )
}

export default NotFound