import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { ADMIN_DASHBOARD } from "../constans/Route";
import secureLocalStorage from "react-secure-storage";

const CheckAuth = ({ loggedIn, role }) => {
  const Role = secureLocalStorage.getItem("role");

  let navigate;
  if (role === Role) {
    navigate = ADMIN_DASHBOARD;
  } else {
    navigate = "/";
  }

  return loggedIn === false ? <Outlet /> : <Navigate to={navigate} replace />;
};

export default CheckAuth;
