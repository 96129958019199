import React, { useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import { getRequest, patchRequest, postRequest } from "../../../components/axiosClient";
import { ADD_PROJECT_PAYMENT, EDIT_PROJECT_PAYMENT, GET_PROJECT_PAYMENT } from "../../../components/utils";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { errorNotification } from "../../../components/toast-notification/common-toast";

const Payment = ({ projectId }) => {
  const [listshow, setListShow] = useState(false);
  const [payments, setPayments] = useState([]);
  const [totalAmount, setTotalAmount] = useState();
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [date, setDate] = useState("");
  const [selectedPayment, setSelectedPayment] = useState(null);

  const navigate = useNavigate();
  // Handle add button click
  const handleAdd = () => {
    setAmount("");
    setNote("");
    setDate("");
    setSelectedPayment(null);
    setListShow(true);
  };

  const getPaymentTableData = async () => {

    try {
      const res = await getRequest(
        `${GET_PROJECT_PAYMENT}?project_id=${projectId}`,
        null,
        navigate
      );
      if (res?.data?.success) {
        setPayments(res?.data?.data?.payments);
        setTotalAmount(res?.data?.data?.totalAmount);
      }
    } catch (error) {
      errorNotification(error);
    }

  }

  useEffect(() => {
    getPaymentTableData();
  }, [])

  const handleSavePayment = async () => {
    if (selectedPayment) {

      try {
        const payload = {
          "payment_id": selectedPayment?.id,
          "note": note,
          "payment": Number(amount),
          "date": date // YYYY-MM-DD
        };
        const res = await patchRequest(
          `${EDIT_PROJECT_PAYMENT}`,
          payload,
          navigate
        );
        if (res.type === 1) {
          await getPaymentTableData();
          handleAdd();
          setListShow(false);
        }
        if (res.type === 2) {
          errorNotification(res.errors?.response?.data?.message)
        }
      } catch (error) {
        errorNotification(error);
      }



    } else {

      try {
        const payload = {
          "project_id": projectId,
          "note": note,
          "payment": Number(amount),
          "date": date // YYYY-MM-DD
        };
        const res = await postRequest(
          `${ADD_PROJECT_PAYMENT}`,
          payload,
          navigate
        );
        if (res.response.data.success) {
          await getPaymentTableData();
          handleAdd();
          setListShow(false);
        }
        if (res.type === 2) {
          navigate("/404");
          errorNotification(res.errors?.response?.data?.message)
        }
      } catch (error) {
        errorNotification(error);
      }

    }
  };

  // Handle edit button
  const handleEdit = (payment) => {
    setAmount(payment?.payment);
    setNote(payment?.note);
    setDate(dayjs(payment?.date)?.format("YYYY-MM-DD"));
    setSelectedPayment(payment);
    setListShow(true);
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setAmount(value);
    }
  };

  return (
    <div className=" hunter_audit_dashboard ">
      <div className="client__subsection">
        <button className="add__client mx-3 " onClick={handleAdd}>
          + Add Payment
        </button>
        <div className="client__listing">
          {payments.length>0?(<div className="client__data pb-4">
            <table className="table client__list__table">
              <thead className="report__header__border">
                <tr className="border-style">
                  <th scope="col" className="font__weight header__first__child">
                    Payment
                  </th>
                  <th scope="col" className="font__weight header__first__child">
                    Notes
                  </th>
                  <th scope="col" className="font__weight header__first__child">
                    Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {payments.map((payment) => (
                  <tr key={payment.id}>
                    <td className="table_tr_style align-middle">
                      ${payment.payment}
                    </td>
                    <td className="table_tr_style align-middle">
                      {payment.note}
                    </td>
                    <td className="table_tr_style align-middle">
                      {payment.date ? dayjs(payment.date).format("DD-MM-YYYY") : '--'}
                    </td>
                    <td className="text-center align-middle d-flex justify-content-end !border-b-0">
                      <button
                        className="bg-[#f47e53] text-[#fff] w-[fit-content] interFontFamily py-[8px] px-[18px] rounded-[6px] text-[14px] cursor-pointer font-[700] ms-2"
                        onClick={() => handleEdit(payment)}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="total-amount mt-3">
              <h5>${totalAmount} : Total Amount</h5>
            </div>
          </div>):<></>}
        </div>
        <Modal
          className="client-modal"
          title={selectedPayment ? "Edit Payment" : "Add Payment"}
          onClose={() => setListShow(false)}
          show={listshow}
        >
          <div className="modal-body add__client__form">
            <div>
              <h6 className="modal-heading">Payment</h6>
              <input
                placeholder="$ Add Amount"
                className="client__name"
                type="text"
                value={amount}
                autoComplete="off"
                onChange={handleAmountChange}
              />
            </div>
            <div className="mt-3">
              <h6 className="modal-heading">Notes</h6>
              <input
                placeholder="Add Note"
                className="client__name text-capitalize"
                type="text"
                value={note}
                autoComplete="off"
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
            <div className="mt-3">
              <h6 className="modal-heading">Date</h6>
              <input
                placeholder="Enter Date"
                className="client__name"
                type="date"
                value={date}
                autoComplete="off"
                onChange={(e) => setDate(dayjs(e.target.value).format("YYYY-MM-DD"))}
              />
            </div>
            <div className="add__close__section mt-3">
              <button
                type="button"
                className="client__save__btn"
                onClick={handleSavePayment}
              >
                {selectedPayment ? "Update" : "Add"}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Payment;
